import useFlaggyStore from '~/store/flaggy'

export default function (context) {
    const { route, store, error, redirect, $toast, i18n } = context
    const flaggyStore = useFlaggyStore()

    const { hasContract, getIdOrga, getPackage } = flaggyStore
    const currentIdOrga = parseInt(route.params.idOrga, 10)
    const hasRightContractOrganization = getIdOrga === currentIdOrga
    const message = 'errors.no_access'

    if (
        route.params.idOrga &&
        (!hasRightContractOrganization || !getPackage || !hasContract)
    ) {
        return flaggyStore
            .fetchContract(context, currentIdOrga)
            .then(() => {
                if (!flaggyStore.hasContract) {
                    const isChargemapAdmin =
                        store.getters['session/isSuperAdmin']
                    if (isChargemapAdmin) {
                        const orgaParameterRoute = `/organizations/${route.params.idOrga}/parameters`
                        const isOrgaParameterRoute =
                            route.path === orgaParameterRoute

                        if (!isOrgaParameterRoute) {
                            if ($toast && i18n && $toast.global) {
                                $toast.global?.error({
                                    title: i18n.t('errors.no-contract')
                                })
                            }
                            return redirect(
                                `${orgaParameterRoute}?no-contract=true#functionalities`
                            )
                        }
                    } else {
                        return error({ statusCode: 402, message })
                    }
                }
            })
            .catch(() => error({ statusCode: 403, message }))
    }
}
